// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"
import SearchController from "./search_controller"
import { Toggle } from "tailwindcss-stimulus-components"

// Register each controller with Stimulus
application.register("search", SearchController)
application.register('toggle', Toggle)

