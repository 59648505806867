import { Controller } from "@hotwired/stimulus"
import Fuse from 'fuse.js'

export default class extends Controller {
  static targets = ["input", "results"]
  static values = {
    url: String
  }

  connect() {
    console.debug("Search controller connected")
    if (this.hasUrlValue) {
        this.loadSearchData()
      } else {
        console.error("No search data URL provided")
      }
    this.loadSearchData()
    this.debounceTimer = null
    this.noResultsTimer = null
    this.bindClickOutside()
  }

  disconnect() {
    this.unbindClickOutside()
    this.clearTimers()
  }

  bindClickOutside() {
    this.clickOutsideHandler = this.clickOutside.bind(this)
    document.addEventListener('click', this.clickOutsideHandler)
  }

  unbindClickOutside() {
    document.removeEventListener('click', this.clickOutsideHandler)
  }

  clickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.hideResults()
    }
  }

  clearTimers() {
    clearTimeout(this.debounceTimer)
    clearTimeout(this.noResultsTimer)
  }

  loadSearchData() {
    console.debug("Loading search data from:", this.urlValue)
    fetch(this.urlValue)
      .then(response => response.json())
      .then(data => {
        this.searchData = data
        this.initializeFuse()
      })
  }

    initializeFuse() {
    const options = {
      includeScore: true,
      includeMatches: true,
      threshold: 0.3,
      ignoreLocation: true,
      keys: [
        { name: 'title', weight: 0.7 },
        { name: 'content', weight: 0.3 }
      ],
      minMatchCharLength: 3,
      useExtendedSearch: true
    }

    this.fuse = new Fuse(this.searchData, options)
  }

  search() {
    this.clearTimers()
    
    if (this.inputTarget.value.trim() === '') {
      this.hideResults()
      return
    }

    this.debounceTimer = setTimeout(() => {
      this.performSearch()
    }, 300) // 300ms debounce
  }

  performSearch() {
    const query = this.inputTarget.value.trim()
    console.debug("Performing search for:", query)
    
    if (query === '') {
      this.hideResults()
      return
    }

    const results = this.fuse.search(query)
    this.displayResults(results)
  }

  displayResults(results) {
    this.resultsTarget.innerHTML = ''

    if (results.length === 0) {
      this.resultsTarget.innerHTML = '<p class="text-gray-500 text-center py-4">No results found</p>'
      this.showResults()
      
      // Hide "No results found" after 3 seconds
      this.noResultsTimer = setTimeout(() => {
        this.hideResults()
      }, 3000)
    } else {
      const ul = document.createElement('ul')
      ul.className = 'divide-y divide-gray-200'

      results.forEach(result => {
        const li = document.createElement('li')
        li.className = 'py-2 hover:bg-gray-50 transition-colors duration-150'

        const a = document.createElement('a')
        a.href = result.item.url
        a.className = 'block px-4'

        const title = document.createElement('h3')
        title.className = 'text-lg font-semibold text-blue-600 hover:underline'
        title.textContent = result.item.title

        const snippet = document.createElement('p')
        snippet.className = 'text-sm text-gray-600 mt-1'
        snippet.textContent = this.getSnippet(result.item.content, result.matches)

        a.appendChild(title)
        a.appendChild(snippet)
        li.appendChild(a)
        ul.appendChild(li)
      })

      this.resultsTarget.appendChild(ul)
      this.showResults()
    }
  }

  showResults() {
    this.resultsTarget.classList.remove('hidden')
  }

  hideResults() {
    this.resultsTarget.classList.add('hidden')
  }

  getSnippet(content, matches) {
    const contentMatch = matches.find(match => match.key === 'content')
    if (contentMatch && contentMatch.indices.length > 0) {
      const [start, end] = contentMatch.indices[0]
      const snippetStart = Math.max(0, start - 50)
      const snippetEnd = Math.min(content.length, end + 50)
      let snippet = content.substring(snippetStart, snippetEnd)
      
      if (snippetStart > 0) snippet = '...' + snippet
      if (snippetEnd < content.length) snippet += '...'
      
      return snippet
    }
    return content.substring(0, 100) + '...'
  }
}